<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                    class="wizard wizard-2"
                    id="kt_wizard_v2"
                    data-wizard-state="step-first"
                    data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-6">
                                <h4>
                                    {{ inventoryItemId ? 'Edit inventory item ' : 'Add inventory item' }}
                                </h4>
                                <router-link :to="{ name: 'dashboard' }">
                                Dashboard \
                                </router-link>
                                <router-link :to="{name: 'inventory-item'}">
                                Inventory item
                                </router-link>

                            </div>
                        </div>

                        <div class="row">
                            <v-col cols="12" md="12">
                                <v-text-field
                                    outlined
                                    dense
                                    :error="$v.inventoryItem.title.$error"
                                    v-model="inventoryItem.title"
                                >
                                    <template v-slot:label>Title <span class="text-danger">*</span></template>
                                </v-text-field>
                                <span class="text-danger" v-if="$v.inventoryItem.title.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.title">{{ errors.title[0] }}</div>
                            </v-col>

                            <v-col cols="12" md="12">
                                <label>
                                    Description <span class="text-danger">*</span>
                                </label>
                                <ckeditor
                                    v-model="inventoryItem.description"
                                    :config="editorConfig"
                                ></ckeditor>
                                <span class="text-danger" v-if="$v.inventoryItem.description.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.description">{{ errors.description[0] }}</div>

                            </v-col>

                            <v-col cols="12" md="6" class="mt-3">
                                <v-text-field
                                    outlined
                                    type="number"
                                    dense
                                    :error="$v.inventoryItem.qty.$error"
                                    v-model="inventoryItem.qty"
                                >
                                    <template v-slot:label>Quantity <span class="text-danger">*</span></template>
                                </v-text-field>
                                <span class="text-danger" v-if="$v.inventoryItem.qty.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.qty">{{ errors.qty[0] }}</div>
                                <div class="text-danger" v-if="errors.qty">{{ errors.qty[1] }}</div>

                            </v-col>

                            <v-col cols="12" md="6" class="mt-3">
                                <v-text-field
                                    outlined
                                    type="number"
                                    dense
                                    :error="$v.inventoryItem.reorder_level.$error"
                                    v-model="inventoryItem.reorder_level"
                                >
                                    <template v-slot:label>Reorder level <span class="text-danger">*</span></template>
                                </v-text-field>
                                <span class="text-danger" v-if="$v.inventoryItem.reorder_level.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.reorder_level">{{ errors.reorder_level[0] }}</div>
                                <div class="text-danger" v-if="errors.reorder_level">{{ errors.reorder_level[1] }}</div>
                            </v-col>

                            <v-col cols="12" md="6" class="mt-3">
                                <v-autocomplete
                                    :items="addressBooks"
                                    outlined
                                    dense
                                    :error="$v.inventoryItem.address_book_id.$error"
                                    v-model="inventoryItem.address_book_id"
                                    item-text="full_address"
                                    item-value="id"
                                >
                                    <template v-slot:label>
                                        Contact <span class="text-danger">*</span>
                                    </template>
                                </v-autocomplete>
                                <span class="text-danger" v-if="$v.inventoryItem.address_book_id.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.address_book_id">{{ errors.address_book_id[0] }}</div>
                            </v-col>

                            <v-col cols="12" md="6" class="mt-3">
                                <v-autocomplete
                                    :items="categories"
                                    outlined
                                    dense
                                    :error="$v.inventoryItem.category_id.$error"
                                    v-model="inventoryItem.category_id"
                                    item-text="title"
                                    item-value="id"
                                >
                                    <template v-slot:label>
                                        Category <span class="text-danger">*</span>
                                    </template>
                                </v-autocomplete>
                                <span class="text-danger" v-if="$v.inventoryItem.category_id.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.category_id">{{ errors.category_id[0] }}</div>
                            </v-col>

                            <v-col cols="12" md="4" class="mt-2">
                                Is assignable ?
                                <v-switch
                                    v-model="inventoryItem.is_assignable"
                                    :label="inventoryItem.is_assignable ? 'Yes' : 'No'"
                                ></v-switch>
                            </v-col>

                            <v-col cols="12" md="4" class="mt-2">
                                Requires authorisation ?
                                <v-switch
                                    v-model="inventoryItem.requires_authorisation"
                                    :label="inventoryItem.requires_authorisation ? 'Yes' : 'No'"
                                ></v-switch>
                            </v-col>

                            <v-col cols="12" md="4" class="mt-2">
                                Status
                                <v-switch
                                    v-model="inventoryItem.is_active"
                                    :label="inventoryItem.is_active ? 'Active' : 'Inactive'"
                                ></v-switch>
                            </v-col>

                            <v-col cols="12" md="4" class="mt-2" v-if="!inventoryItemId">
                                Enable accession
                                <v-switch
                                    v-model="inventoryItem.enable_accession"
                                    :label="inventoryItem.enable_accession ? 'Yes' : 'No'"
                                ></v-switch>
                            </v-col>

                            <v-col cols="12" md="4" class="mt-2">
                                Returnable
                                <v-switch
                                    v-model="inventoryItem.is_returnable"
                                    :label="inventoryItem.is_returnable ? 'Yes' : 'No'"
                                ></v-switch>
                            </v-col>
                        </div>

                        <div class="row">
                            <div class="col-12 text-right">
                                <v-btn
                                    class="text-gray btn btn-standard"
                                    depressed
                                    @click="cancel"
                                >Cancel
                                </v-btn>
                                <v-btn
                                    class="text-white ml-2 btn btn-primary"
                                    depressed
                                    @click="createOrUpdate"
                                    :loading="isBusy"
                                    v-if="checkIsAccessible('inventory-item', 'create') || checkIsAccessible('inventory-item', 'edit')"
                                >
                                  {{ inventoryItemId ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import InventoryItemService from "@/core/services/inventory-item/InventoryItemService";
import CategoryService from "@/core/services/category/CategoryService";
import AddressBookService from "@/core/services/address/AddressBookService";

const inventoryItem = new InventoryItemService();
const category = new CategoryService();
const addressBook = new AddressBookService();

export default {
    validations: {
        inventoryItem: {
            address_book_id: { required },
            category_id: {required},
            title: {required},
            description: {required},
            qty: {required},
            reorder_level: {required},
            is_assignable: {},
            requires_authorisation: {},
            is_active: {},
            enable_accession: {},
            is_returnable: {},
        }
    },
    data() {
        return {
            dialog: false,
            isBusy: false,
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            inventoryItem: {
                address_book_id: null,
                category_id: null,
                title: '',
                description: '',
                qty: null,
                reorder_level: null,
                is_assignable: false,
                requires_authorisation: false,
                is_active: true,
                enable_accession: false,
                is_returnable: false
            },
            addressBooks: [],
            categories: [],
            errors: [],
        };
    },
    mounted(){
        this.getCategories();
        this.getAddressBooks();
        if(this.inventoryItemId){
            this.getInventoryItem();
        }
    },
    computed:{
        inventoryItemId(){
            return this.$route.params.inventoryItemId;
        }
    },
    methods: {
        cancel(){
            this.$tabs.close();
        },
        getCategories() {
            category
            .all()
            .then(response => {
                this.categories = response.data.categories;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        getAddressBooks() {
            addressBook
            .all()
            .then(response => {
                this.addressBooks = response.data.addressBooks;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.inventoryItem.$touch();
            if (this.$v.inventoryItem.$error) {
                setTimeout(() => {
                    this.$v.inventoryItem.$reset();
                }, 3000);
            } else {
                if (this.inventoryItemId) {
                    this.updateInventoryItem();
                } else {
                    this.createInventoryItem();
                }
            }
        },
        updateInventoryItem() {
            this.isBusy = true;
            inventoryItem
            .update(this.inventoryItem.id, this.inventoryItem)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.$tabs.close();
                this.resetForm();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createInventoryItem() {
            this.isBusy = true;
            inventoryItem
            .store(this.inventoryItem)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$snotify.success("Information added");
                this.$tabs.close();
            })
            .catch(error => {
                this.isBusy = false;
                this.errors = error.errors;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.inventoryItem.$reset();
            this.inventoryItem = {
                address_book_id: null,
                category_id: null,
                title: '',
                description: '',
                qty: null,
                reorder_level: null,
                is_assignable: false,
                requires_authorisation: false,
                is_active: true,
                enable_accession: false,
                is_returnable: false
            };
        },
        getInventoryItem(){
            inventoryItem
            .show(this.inventoryItemId)
            .then((res) => {
                this.inventoryItem = res.data.inventoryItem;
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        }
    }
}
</script>
